exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-vaccination-portal-js": () => import("./../../../src/pages/covid-vaccination-portal.js" /* webpackChunkName: "component---src-pages-covid-vaccination-portal-js" */),
  "component---src-pages-hytide-js": () => import("./../../../src/pages/hytide.js" /* webpackChunkName: "component---src-pages-hytide-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lifetime-js": () => import("./../../../src/pages/lifetime.js" /* webpackChunkName: "component---src-pages-lifetime-js" */),
  "component---src-pages-palettte-app-js": () => import("./../../../src/pages/palettte-app.js" /* webpackChunkName: "component---src-pages-palettte-app-js" */)
}

